@import url('https://fonts.googleapis.com/css?family=Lato|Roboto:400,500,700&display=swap');
body{font-family: 'Roboto', sans-serif; font-weight: 500;  transition: all .5s; width: 100%; position: relative;background: #EAEEEF;}
.btn-medium{color: #000;
  background-color: #cccccc;
  border-color: #cccccc;}
.btn-medium:hover{ background-color: #f1f1f1;}
a, button{ cursor: pointer !important;}
a {text-decoration: none !important;}
*{ outline: none !important;}
.index10{z-index: 100;}
.col-custom-3 {
  width: 3%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: auto;
}
.col-custom-4 {
  width: 4%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: auto;
}
.col-custom-5 {
  width: 5%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-2 {
  width: 2%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-7 {
  width: 7%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-13 {
  width: 13%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-10 {
  width: 10%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
  display: inline;
}
.col-custom-12 {
  width: 12%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-13 {
  width: 13%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-25 {
  width: 25%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-20 {
  width: 20%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-15 {
  width: 15%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-17 {
  width: 17%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-40 {
  width: 40%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-35 {
  width: 35%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.col-custom-30 {
  width: 30%;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 auto;
}
.txt-purple{ color:#360066}
.panel-title{ font-size: 1.75rem;}
.relative{ position: relative;   }
.content{ width: 100%; transition: all .5s; }
.click-help{ width: 75%; }
.modal-open{ padding: 0 !important }
.click-help .modal{ width: 75%; padding: 0; }
.z-index-10{ z-index: 10;}
.overlay-link {  position: absolute;  top: 0;  right: 0;  bottom: 0;  left: 0;  z-index: 9;}
.header {  padding-top: 25px;  padding-top: 0;  top: 0;  padding-bottom: 20px;  padding-bottom: 0;  z-index: 9;  background-color: #000;  background: #222222 !important;  position: fixed;
    width: 100%;  left: 0;}
.header .logo {  float: left;  padding-top: 7px;  padding: 10px 0;  padding: 0;  margin-right: 45px;  position: relative;  z-index: 100;  font-size: 0;  line-height: 0;}
.header .logo img {  display: block;  padding: 8px 0 9px;  width: 180px;}
.header .navbar {  margin-bottom: 0;  float: left;  margin-right: 29px;  min-height: 0;  z-index: 0;  border: none;  padding: 0px;}
.navbar .nav {  position: relative;  left: 0px;  float: left;  margin: 0px 10px 0px 0px;  padding: 0px;}
.header .navbar ul li {  padding: 27px 22px 22px;  padding: 14px 22px 9px;}
.header .navbar ul li a {  font-size: 16px;  line-height: 26px;  line-height: 22px;  color: #fff;  font-weight: 400;  padding: 0;}
.header .navbar ul li a {  position: relative;}
.navbar .nav > li > a:hover {  background-color: transparent;  color: rgb(255, 255, 255);  text-decoration: none;}
.header .navbar ul li a:hover {  background-color: transparent;}
.header .navbar ul .products-menu:hover, .header .navbar ul .support-menu:hover {  background: #fff;  border-radius: 2px 2px 0 0;}
.header .navbar ul .products-menu:hover a, .header .navbar ul .support-menu:hover a {  color: #484a49 !important;}

/* Custom new styles start */
body{ background: none;}

@font-face {
  font-family: 'hkgroteskpro-bold-webfont';
  src: url('webfonts/hkgroteskpro-regular-webfont.woff2') format('woff2'),
  url('webfonts/hkgroteskpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
.repair-form{ color:#939598; font-family: 'hkgroteskpro-bold-webfont';}
.repair-form .banner{padding-top: 92px !important;
  padding-bottom: 90px !important;}
.repair-form h1{font-size: 80px;  line-height: 80px;  color: #000;}
.repair-form h2 {  font-size: 56px;  line-height: 64px;  color: #000;}
.bg-light {
  background-color: #F2F1EA !important;
}
.repair-form .custom-styles-col .mb-3{ margin-bottom: 3rem !important;}
.repair-form label{ margin-bottom: .5rem; }
.repair-form .btn {
  font-size: 24px;
  padding: 20px 25px;
}
.repair-form  .form-switch .form-check-input{    margin-top: -0.2rem;}
.repair-form .btn-primary{color: #000;
  background-color: #FFC107;
  border-color: #FFC107;}
  .repair-form .btn-primary:hover{background: #939598!important;
    border-color: #939598;}

    .repair-form  ::placeholder, .repair-form option, .repair-form #country, .repair-form #file-name, .repair-form     .accordion-button, .repair-form input, .repair-form select, .repair-form textarea{font-size: 24px !important;
      color: #939598;
      font-weight: 400;}

/* Custom new styles start */


@media (max-width: 991px){
#bs-navbar {    position: fixed;    top: 0;    width: auto;    padding-top: 70px!important;    right: -300px;    background: #414143;}
#bs-navbar>ul {  text-align: left;  width: 300px;  margin: 0 auto;}
.header .navbar ul li {  padding: 0;}
.header .navbar ul li>a {  padding: 5px 15px;}
.header .logo {  padding-top: 8px;  padding-bottom: 8px;  padding: 0;  margin-right: 10px;}
.repair-form h1{ font-size: 50px; line-height: 60px;}
.repair-form h2{ font-size: 45px; line-height: 50px;}
}


/*Footer styles*/
.footer{ background: #000; padding: 2rem 1rem 3rem;}
.footer h2{ border-bottom:3px solid #939598; font-size: 1.5em; font-weight: 700; color: #939598;padding-bottom: .5rem; display: inline-block;}
.footer .nav li{ display: block; font-size: 0.9375rem;}
.footer .nav li a{ color: #939598; line-height: 25px;}
.footer .nav li a:hover{ color: #e6e6e6;}
.footer .footer-social-media{ list-style: none; padding: 0; margin: 0;}
.footer .footer-social-media a{ font-size: 2em; color: #939598;}
.footer .footer-social-media a:hover{ color:#e6e6e6; }
.footer .footer-social-media a i{ margin: 10px;}
.footer .phone{color: #939598;    font-size: 1em;    margin-left: 10px;}
/* .footer .row{ margin: 0 -30px;} */
/* .footer .col-md-auto{ padding: 0 30px;} */
.vitec-list::after, .vitec-section .cols-container:after {  display: block;  clear: both;  content: ""}
.vitec-section * {  font-family: Arial, Helvetica, sans-serif;  padding: 0;  margin: 0;  font-size: inherit}
.vitec-section .container {  max-width: 1180px;  margin: 0 auto;  padding: 0 30px}
.vitec-section .yellow {  color: #fecb00}
.vitec-section .collapsible-section {  background: #000}
.vitec-section {  background-color: #535353}
.vitec-menu{  -webkit-column-count: 3; /* Chrome, Safari, Opera */  -moz-column-count: 3; /* Firefox */  column-count: 3;}
.vitec-menu > li{  -webkit-column-break-inside: avoid;          page-break-inside: avoid;               break-inside: avoid;}
.vitec-section .close-vitec-menu {  display: block;  width: 22px;  height: 22px;  background: url(../images/close.png) no-repeat;  position: absolute;  right: 0;  top: 30px;  cursor: pointer;}
.vitec-section h2 {  font-size: 37px;  font-weight: 700;  color: #fff;  line-height: 36px}
.vitec-section h3 {  font-size: 22px;  font-weight: 700;  line-height: 30px;  color: #fff;  padding: 5px 0}
ul.vitec-menu, ul.vitec-menu li, ul.vitec-menu li a {  display: block}
ul.vitec-menu ul {  margin-bottom: 25px}
.vitec-section h2 span {  display: block}
.vitec-section .yellow-separator {  padding-left: 90px;  border-left: 2px solid #fecb00}
.vitec {  padding: 5px 0;  display: block}
.vitec::after{  content: "";  clear: both;  display: block;}
.vitec .vitec-logo {  float: left;  line-height: 13px;  font-size: 11px;  font-weight: 400;  color: #fff;  padding-left: 40px;  position: relative}
.vitec .vitec-logo .logo-image {  display: block;  height: 43px;  width: 130px;  background: url(../images/vitec-logo.png) center left no-repeat;  background-size: contain;}
.vitec .vitec-sites {  padding: 8px 0 7px;  float: right;  cursor: pointer;  line-height: 26px;  font-size: 13px;  font-weight: 700;  color: #fff;  padding-right: 15px;  background: url(../images/uparrow.png) center right no-repeat}
.vitec .vitec-sites.active {  background: url(../images/downarrow.png) center right no-repeat}
.vitec-list {  padding: 65px 0;  position: relative}
.vitec-list a {  font-size: 14px;  color: #fff;  text-decoration: none;  line-height: 18px;  padding: 6px 0}
.vitec-list a:hover {  color: #fff}
.vitec-menu.menucoltwoA, .vitec-menu.menucoltwoB {  display: none}
@media (min-width:1241px) {
  .vitec-section .cols-container .cols.col-large-4 {    width: 33.33%;    float: left  }
    .vitec-section .cols-container .cols.col-large-2 {    width: 16.66%;    float: left  }
  .vitec-section .cols-container .cols.col-large-10 {    width: 83.33%;    float: left  }
}
@media (max-width:1400px)
{
  .repair-form .btn-primary{ font-size: 20px; padding:27px 13px !important;}
}
@media (max-width:1240px) {  
  .vitec-menu  {    -webkit-column-count: 2; /* Chrome, Safari, Opera */    -moz-column-count: 2; /* Firefox */    column-count: 2;  }
  .vitec-section .hidden-medium {    display: none!important  }  
  .vitec-list {    padding: 40px 0  }
  .vitec-section h2 {    padding-bottom: 36px  }
  .vitec-section .yellow-separator {    padding-left: 30px  }
  .repair-form h1{ font-size: 60px;}
}

@media (max-width:768px) {    
  .vitec-menu    {      -webkit-column-count: 1; /* Chrome, Safari, Opera */      -moz-column-count: 1; /* Firefox */      column-count: 1;    }
  .vitec-section .cols {    display: block;    float: none!important;    width: auto!important  }
  .vitec-section .yellow-separator {    padding-left: 0;    border: none  }
    .vitec-list {    padding: 30px 0 0  }
  .vitec-section h2 {    font-size: 30px;    line-height: 30px;    padding-bottom: 16px;    padding-left: 20px  }
  .vitec-section .container {    padding: 0 20px  }
  .vitec-section .collapsible-section .container {    padding: 0  }
  ul.vitec-menu ul {    margin: 0  }
  .vitec-section .close-vitec-menu {    right: 20px  }
  .vitec-list .vitec-menu a, .vitec-section h3 {    padding: 20px 15px;    line-height: 20px  }
  .vitec-section h3 {    font-size: 15px;    letter-spacing: .1em;    text-transform: uppercase;    cursor: pointer;    border-bottom: 1px solid #333;    background: url(../images/downarrow.png) right 20px center no-repeat;    padding-right: 35px  }  .vitec-list .vitec-menu a {
    background-color: rgba(30, 30, 30, 0);    transition: all ease-in-out .4s  }
  .vitec-list .vitec-menu a:hover {    background-color: rgba(30, 30, 30, 1)  }
  .vitec-section h3.active {    background-image: url(../images/uparrow.png)  }
  ul.vitec-menu>li>ul {    border-bottom: 1px solid #333;    background-color: #191919;    display: none  }

.heading-col{ display:block !important;}
.heading-col a{ margin-top: 20px;}
  
}
@media (max-width:560px)
{
  .col-custom-3 , .col-custom-4, .col-custom-5,   .col-custom-2,   .col-custom-7 ,  .col-custom-13 ,  .col-custom-10, .col-custom-12,  .col-custom-13, 
  .col-custom-25,   .col-custom-20,   .col-custom-15,  .col-custom-17,   .col-custom-40,   .col-custom-35,   .col-custom-30{
    width: 100%;
    float: none;
    padding-left: 15px;
    padding-right: 15px;
    flex: auto;
  }

  .repair-form h1{ font-size: 40px; line-height: 40px;}

.repair-form h2{ font-size: 35px; line-height:40px;}
.repair-form .btn{ font-size: 20px;}
.repair-form .banner{ padding: 50px 0;}
}