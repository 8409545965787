.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-input {
  text-transform: uppercase;
}
.custom-input::-webkit-input-placeholder {
  /* Edge */
  text-transform: capitalize !important;
}
.custom-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-transform: capitalize !important;
}
.custom-input::placeholder {
  text-transform: capitalize !important;
}
